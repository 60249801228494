
/* sets margins for page unless overridden */
.home {
  margin-left: 3vw;
  margin-right: 3vw;
  padding-bottom: 5vh;
  padding-top: 20vh;
}

/* flex containter containing the "main quote container" and runner image */
.top-container {
  display: flex;
  justify-content: space-between;
  margin-left: 8vw;
  margin-bottom: 8vh;
}

/* runner image */
.runner {
  max-width: 100%;
  height: auto;
  align-self: start;
}

/* media queries that have runner next to quote for desktop site, and below quote for mobile/tablet */

@media (max-width: 1200px) {
  .top-container {
    flex-direction: column;
    margin-top: 8vh;
    margin-bottom: 10vh;
    margin-left: auto;
    margin-right: auto;
  }
  .runner {
    width: 60vw;
    align-self: center;
    margin-right: 5vw;
    margin-top: 10vh;
  }
  .main-quote {
    text-align: center;
  }
  .join {
    padding-left: 2vw;
    padding-right: 2vw;
    align-self: center;
  }
}

@media (max-width: 1400px) {
  .runner {
    width: 40vw;
  }
}

@media (max-width: 900px) {
  .runner {
    width: 70vw;
  }
}

@media (min-width: 1400px) {
  .runner {
    width: 35vw;
  }
}

/* container containing main quote, text below it, and join now button */
.main-quote {
  display: block;
  padding-top: 6vh;
}

/* text in main quote ("tailored formula for peak performance") */
h4 {
  font-family: Outfit;
  font-weight: 700;
  font-size: 5rem;
}
@media (min-width: 1700px) {
  h4 {
    font-size: 6.0rem;
  }
}
@media (max-width: 1700px) {
  h4 {
    font-size: 5.5rem;
  }
}
@media (max-width: 1400px) {
  h4 {
    font-size: 5.0rem;
  }
}
@media (max-width: 1200px) {
  h4 {
    font-size: 6rem;
  }
}
@media (max-width: 1050px) {
  h4 {
    font-size: 5.5rem;
  }
}
@media (max-width: 900px) {
  h4 {
    font-size: 5rem;
  }
}
@media (max-width: 600px) {
  h4 {
    font-size: 4.5rem;
  }
}

@media (max-width: 450px) {
  h4 {
    font-size: 4rem;
  }
}
.long-run {
  font-family: Outfit;
  font-weight: 600;
  font-size: 1.5rem;
}
/* text below main quote */
.quote-bottom {
  font-family: Outfit;
  font-weight: 200;
  letter-spacing: 0.03vw;
  margin-bottom: 3vh;
  margin-top: 2vh;
  font-size: 1.6rem;
}

/* join now button */
.join {
  margin-top: 1vh;
  border-top-left-radius: 40px;
  border-top-right-radius: 40px;
  border-bottom-left-radius: 40px;
  border-bottom-right-radius: 40px;
  background-color: hsla(161.44329896907217, 44.70%, 57.45%, 1.00);
  font-family: Outfit;
  color: hsla(0, 0.00%, 100.00%, 1.00);
  font-size: 1.6rem;
  font-weight: 600;
  padding: 1.5vh 2vw 1.5vh 2vw;
  border-style: none;
}

  /* "partners with" text */
.partners-with {
  font-family: Outfit;
  font-size: 2rem;
  font-weight: 600;
  letter-spacing: 0.3vh;
  margin-top: 1vh;
  margin-bottom: 1.5vh;
  margin-left: 5vw;
}

/* box around partner logos */
.partners {
  padding-top: 1vh;
  padding-right: 1.5vw;
  padding-bottom: 1vh;
  padding-left: 1.5vw;
  border-top-style: none;
  border-top-width: 0.1vw;
  border-top-color: black;
  border-right-style: none;
  border-right-width: 0.1vw;
  border-right-color: black;
  border-bottom-style: none;
  border-bottom-width: 0.1vw;
  border-bottom-color: black;
  border-left-style: none;
  border-left-width: 0.1vw;
  border-left-color: black;
  border-top-left-radius: 3vw;
  border-top-right-radius: 3vw;
  border-bottom-left-radius: 3vw;
  border-bottom-right-radius: 3vw;
  display: flex;
  justify-content: center;
  margin-right: 4vw;
  margin-left: 4vw;
  background-color: hsla(160.00000000000006, 0.00%, 100.00%, 1.00);
  box-shadow: -2px 2px 80px -20px hsla(162.1875, 45.07%, 72.16%, 1.00);
  outline-color: #555;
  outline-offset: 0px;
  outline-style: none;
  outline-width: 3px;
  color: hsla(161.44329896907217, 44.70%, 57.45%, 1.00);
  mix-blend-mode: normal;
  margin-bottom: 30vh;
}

/* next 3 rules are for partner logos*/

.microsoft, .sutardja {
  width: 16vw;
  margin-right: 5vw; 
  mix-blend-mode: exclusion;
}

.launch {
  width: 16vw;
  margin-right: 5vw; 
  align-self: center;
  mix-blend-mode: exclusion;
}

.ballard {
  width: 17vw;
  align-self: flex-end;
  mix-blend-mode: exclusion;
}

@media (max-width: 1200px) {
  .microsoft, .sutardja, .launch, .ballard {
    width: 30vw;
    margin-right: 0;
  }
  .partners {
    flex-wrap: wrap;
    justify-content: space-around;

  }
  .partners-with {
    text-align: center;
  }
}

@media (max-width: 600px) {
  .microsoft, .sutardja, .launch, .ballard {
    width: 40vw;
    align-self: center;
  }
  .partners {
    flex-direction: column;
  }
  .ballard {
    margin-top: 2vh;
  }
}

@media (max-width: 450px) {
  .microsoft, .sutardja, .launch, .ballard {
    width: 45vw;
  }
  .partners {
    flex-wrap: wrap;
  }
}

/* helix image */
.dna {   
    height: auto;
    margin-right: 5vw;
    align-self: start;
    }

/* media queries that have dna next to our-vision for desktop site, and below our-vision for mobile/tablet */

@media (max-width: 1200px) {
  .our-vision {
    flex-wrap: wrap-reverse;
  }
  .dna {
    margin-top: 5vh;
    margin-bottom: 5vh;
    width: 35vw;
    display: none;
  }
  .vision-text {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }
  .learn {
    align-self: center;
  }
}

@media (min-width: 1200px) {
  .dna {
    width: 30vw;
  }
  .vision-title {
    text-align: right;
  }
  .vision-body {
    text-align: right;
  }
  .learn {
    align-self: end;
  }
}

/* flex containter containing the vision-text container and dna image */
.our-vision {
  display: flex;
  margin-top: 20vh;
  justify-content: center;
}

/* flex container containing our vision title, body, and learn more button */
.vision-text {
    display: flex;
    flex-direction: column;
    margin-left: 5vw;
    margin-top: 0vh;
}

/* "our vision" title */
.vision-title {
  font-size: 4rem;
}
@media (max-width: 660px) {
  .vision-title {
    font-size: 3.0rem;
  }
}
@media (min-width: 1200px) {
  .vision-title {
    font-size: 3.5rem;
  }
}
@media (min-width: 1400px) {
  .vision-title {
    font-size: 4.0rem;
  }
}

/* text under "our vision" */
.vision-body {
  font-family: Outfit;
  font-size: 1.5rem;
  line-height: 4.5vh;
  font-weight: 300;
}

/* learn more button */
.learn {
  display: block;
  padding-top: 1.3vh;
  padding-bottom: 1.3vh;
  padding-left: 2vw;
  padding-right: 2vw;
  border-top-left-radius: 40px;
  border-top-right-radius: 40px;
  border-bottom-left-radius: 40px;
  border-bottom-right-radius: 40px;
  background-color: hsla(161.44329896907217, 44.70%, 57.45%, 1.00);
  font-size: 1.7rem;
  font-weight: 600;
  color:white;
  border-style: none;
  margin-top: 2.3vh;
}

/* container around the faq section, which includes the heading/subheading and the 5 FAQ components */
.faq-section {
  position: relative;
  z-index: 2;
  display: block;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: 5vw;
  padding-left: 5vw;
}

/* "Frequently asked questions" text */
.faq-heading {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Outfit;
  font-size: 5rem;
  font-weight: 700;
  text-align: center;
  margin-top: 25vh;
}

@media (max-width: 1200px) {
  .faq-heading {
    margin-top: 15vh;
  }
}

@media (max-width: 600px) {
  .faq-heading {
    font-size: 4.0rem;
  }
}

@media (max-width: 450px) {
  .faq-heading {
    font-size: 3.5rem;
  }
}

/* "Got questions? We've got answers" text */
.faq-subheading {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Outfit;
  font-weight: 300;
  font-size: 1.5rem;
  margin: 1.4vh 1.4vh;
}

@media (max-width: 450px) {
  .faq-subheading {
    margin-bottom: 2vh;
  }
}

/* container with faq question text and arrow image */
.faq-question {
  display: flex;
  height: 90px;
  align-items: center;
  font-size: 22px;
  font-weight: 800;
  font-family: Outfit;
  cursor: pointer;
}

/* the arrow image in each FAQ, arrow-visible = arrow when FAQ answer is visible, arrow-hidden = arrow when FAQ answer is hidden */
.arrow-visible, .arrow-hidden {
  height: auto;
  width: auto;
  max-height: 1em; /* Sets the maximum height of the image to the text height */
  margin-right: 10px;
}

.arrow-visible {
  transform: rotate(90deg);
  transition: transform 0.5s ease;
}

.arrow-hidden {
  transform: rotate(0deg);
  transition: transform 0.5s ease;
}

/* when faq answer is visible */ 
.faq-visible {
  opacity: 1;
  overflow: hidden;
  transition: opacity 0.50s ease-in-out;
}

/* when faq answer is hidden */
.faq-hidden {
  opacity: 0;
  max-height: 0px; 
  overflow: hidden;
  transition: opacity 0.50s ease-in-out;
}

/* faq answer container */
.faq-answer {
  padding-left: 45px;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: rgba(45, 62, 80, 0.12);
}

/* text in the faq answer */
.faq-answer-text {
  margin-bottom: 3vh;
  margin-top: 3vh;
  float: none;
  font-size: 1.2rem;
  text-align: left;
  font-family: Outfit;
}

/* bolded text in faq answers */
.bold-text-2{
  color: hsla(161.44329896907217, 44.70%, 57.45%, 1.00);
}
